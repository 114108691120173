.main {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 52px);
  overflow: hidden;
  background-color: #fff;
}

// search Area
.searchArea {
  position: relative;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 48px;

  .searchAreaBox {
    position: relative;
    padding: 57px 16px 60px;
    text-align: center;
    background-color: #e8f3ff;
    background-image: url('../images/bg.png');
    background-repeat: no-repeat;
    background-position: -117px 80px;
    background-size: 900px 256px;
    border-radius: 16px;
  }

  .searchAreaHeader {
    position: relative;
    z-index: 2;
    margin-bottom: 24px;
  }

  .searchAreaTitle {
    position: relative;
    z-index: 2;
    padding: 0 20px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    color: #000;
  }

  .searchAreaSubTitle {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #4b4c4d;
  }

  .searchAreaInput {
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: initial;

    :global {
      .ant-select-selector {
        background: transparent !important;
      }

      input.ant-select-selection-search-input {
        background: transparent !important;
        border-color: transparent !important;
        border-width: 0;
      }
    }
  }

  .searchAreaLocation {
    height: 48px;
    padding: 4px 8px 4px 12px;
    margin-top: 16px;
    border: 2px solid #e1eeff;
    border-radius: 40px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  }

  .searchAreaPopular {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
    text-align: left;
    letter-spacing: 0.12px;
  }

  .searchAreaList {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    height: 95px;
    margin-top: 32px;
    overflow: hidden;
  }

  .searchAreaItem {
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%),
      rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(0, 102, 255, 0.1);
    border-radius: 12px;
  }

  .searchAreaItemLink {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .searchAreaItemText {
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #4b4c4d;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .searchAreaImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  .searchAreaImage img {
    top: -20px !important;
    left: 20px !important;
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .mobile-input-location-placeholder {
      color: #b8b8b8 !important;
    }
  }
}
