@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.JobAlertContainer {
  @extend .GlobalNextAppContainer;
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
  margin: 16px auto;
  height: calc(100% - 94px);
  min-height: 50vh;

  @include sm {
    border: none;
  }

  .JobAlertTitle {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0;

    color: #121212;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &_wrapper {
    height: calc(100% - 100px);
    overflow: auto;
    padding-top: 18px;

    @include sm {
      // border: 2px solid #2379ea;
      // border-radius: 5px;
    }
  }

  &_item {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;
  }

  &_desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    color: #515151;
    font-size: 16px;
  }

  &_left {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.36px;
    display: flex;
    width: 80%;
    white-space: nowrap;
  }

  &_filter {
    width: 100%;
    margin-left: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_right {
    img {
      cursor: pointer;
      margin: 0 10px;
    }
  }

  &_noJobAlert {
    padding: 0 22px;
    padding-top: 25px;

    &_backBtn {
      margin: 25px 0 !important;
      height: 44px;
      text-transform: none !important;

      @include sm {
        width: 100%;
      }
    }
  }
}

.loading {
  position: relative;
  height: 50vh;
}

.modal {
  padding: 40px 20px;

  p {
    margin: 0;
  }

  .item {
    margin-bottom: 20px;

    .title,
    .titleFilters {
      color: #515151;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.12px;
      padding-bottom: 20px;
    }

    .titleFilters {
      padding-bottom: 0px;
    }

    .fullWidth {
      width: 100%;
    }

    .content {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .content,
    .select {
      color: #121212;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.15px;
      padding-bottom: 20px;

      @include sm {
        font-size: 18px;
      }
    }

    .select {
      padding-bottom: 0;
      margin-top: 10px;
    }

    :global {
      .MuiInput-underline::before {
        border-bottom: 1px solid #e4e4e4;
      }

      .MuiInput-input {
        font-size: 18px;
      }

      .MuiInputBase-root {
        margin-top: 0;
        padding-bottom: 10px;
        width: 100%;
      }
    }
  }

  .errorInfo {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }

  .deleteTip {
    font-size: 20px;
    color: #121212;
    font-weight: 400;
    line-height: normal;

    @include sm {
      font-size: 18px;
    }
  }
}